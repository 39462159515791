.Pending{
    color: #FF4040 !important;
}
.Inprogress{
    color:#FFAA15 !important;
}
.Completed{
    color:#00C781 !important;
}
@media only screen and (min-width: 768px){
.headingProjectInfo{ margin-top: 60px !important;}

}
@media only screen and (max-width: 1024px){
    .topBar{ padding-left: 16px !important;}
    .pageHeading {
        padding: 16px !important;
    }
     .projectSiteDropdown{ width: 220px !important;}
}
@media only screen and (max-width: 767px){
    .pageHeading{
        flex-direction: column;
        display: block !important;
        height: auto !important;
    }
    .otp > input {
        height: 32px;
        width: 32px !important;
      }

    .subTab{ margin-right: 14px !important;}
    .subTab svg{ width: 20px;height: 20px;}
    .subTab span{ font-size: 16px;}
    .project-legal{ display: block !important;}
    .project-legal span{ text-align: left;}
}


