html,
body {
  margin: 0;
  padding: 0;
  background-color: #f1f1f1;
  height: 100%;
}

#root {
  height: 100%;
}

#Accordion > div {
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 15px;
}

.otp {
  margin-bottom: 20px;
}

.otp > input {
  height: 44px;
  width: 44px !important;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 700;
  border: 2px solid black;
}

.error {
  border: 2px solid red !important;
}

.textIcon {
  pointer-events: all;
  font-size: inherit !important;
  font-weight: 500;
  display: inline !important;
}
.hidden {
  display: none !important;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: fixed;
  z-index: 2002;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #000;
  animation: spinner 0.6s linear infinite;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.pdfobject-container {
  height: 100%;
  background-color: white;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.cursor_pointer {
  cursor: pointer;
}

.cardLabel {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: grey !important;
}

.cardLabel > * {
  color: grey !important;
  font-weight: 400 !important;
}

.label {
  font-size: 16px !important;
  margin-left: 0px;
  font-weight: 500;
}

.react-date-picker__wrapper {
  border: none !important;
  padding: 8px;
  font-weight: 500;
}

.react-date-picker__calendar.react-date-picker__calendar--open {
  width: 100% !important;
}

.react-calendar {
  width: auto !important;
}

.selectFile {
  border: solid 2px white !important;
}

.selectFile + div {
  color: red;
  border: solid 2px white !important;
  outline: none !important;
  position: absolute;
  left: 8px;
  top: 8px;
  cursor: pointer;
}

.selectFile + div > svg{
 stroke: white;
 border: solid 2px white !important;
}